import React from 'react'
import { Link } from 'react-router-dom'
import '../css/Header.css'

function Header() {
    return (
        <div className="Header">
            <nav>
                <div className="title-glass">
                <h2>
                    <i>TX</i>
                </h2>
                <h3>Team Max</h3>
                </div>
                <div className="title-glass options">
                   <Link to="/" className="Link">Home</Link>
                   <Link to="/About" className="Link">About</Link>
                   <a href="https://www.youtube.com/channel/UCpMcsXwMVYqG_Gn8_qRaM3A" className="Link">Youtube</a>
                   <a href="https://www.instagram.com/team___max/" className="Link">Instagram</a>
                </div>
            </nav>
        </div>
    )
}

export default Header
