import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Header from './components/Header'
import CopyrightBar from './components/CopyrightBar'
import './App.css';

function App() {

  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
        </Routes>
      </Router>
      <CopyrightBar/>
    </div>
  );
}

export default App;
