import React from 'react'

function CopyrightBar() {
    return (
        <div className="black-bar">
            ©️2022 Team Max Official. All Rights Reserved.
        </div>
    )
}
export default CopyrightBar
