import React from 'react'

function Home() {
    return (
        <>
            <div className="body-glass">

            </div>
        </>
    )
}

export default Home
